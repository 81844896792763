import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Mackenzie Child on Learning Rails",
  "date": "2014-12-27T00:00:00.000Z",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Here’s `}<a parentName="p" {...{
        "href": "https://medium.com/@mackenziechild/how-i-finally-learned-rails-95e9b832675b"
      }}>{`another great example of a designer learning the basics of Rails for fun`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`I always had ideas I wanted to bring to life, but I didn’t have the money to hire a developer, or know how to build them out myself. I’ve always loved the idea of taking a concept of mine from design, to front-end code, to back-end all myself.`}</p>
    </blockquote>
    <p>{`I love that attitude. Don’t depend on other people to realize your ideas, own the whole process from start to finish.`}</p>
    <p>{`To challenge himself, he decided to `}<a parentName="p" {...{
        "href": "https://medium.com/@mackenziechild/how-i-finally-learned-rails-95e9b832675b"
      }}>{`record 12 video tutorials`}</a>{`, building a different project each time. It might be a good place to start if you want to start learning.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      